import React, { useState } from 'react';
import {
  PaginationButton,
  PaginationNumber,
  PaginationNumberSecondary,
  PaginationContainer,
  PaginationNumbersContainer,
  RotatedImage
} from './Pagination.styles';

const Pagination = ({ data, itemsPerPage = 3 }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <div>
        {currentItems.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>

      <PaginationContainer>
        <PaginationButton
          variant="contained"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <img src="/icons/arrow.svg" alt="pagination arrow left" />
        </PaginationButton>

        <PaginationNumbersContainer>
          <PaginationNumber>
            {currentPage}
          </PaginationNumber>
          <PaginationNumberSecondary>
            /{totalPages}
          </PaginationNumberSecondary>
        </PaginationNumbersContainer>

        <PaginationButton
          variant="contained"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <RotatedImage
            src="/icons/arrow.svg"
            alt="pagination arrow right"
          />
        </PaginationButton>
      </PaginationContainer>
    </div>
  );
};

export default Pagination;
