import React from 'react';
import { Support } from './Support';
import { useTheme } from '@mui/material';

function Footer() {

  const theme = useTheme()

  return (
    <footer className="footer">
      <div className="footer-links">
        <Support label={'Help:'} iconColor={theme.customComponents.paginationNumber.color} />
        <div className='privacy-links'>
        <a
          href="/retningslinjer_for_personvern"
          rel="noreferrer"
          target="_blank"
        >
          Retningslinjer for personvern
        </a>
        <a href="/vilkar_for_bruk" rel="noreferrer" target="_blank">
          Vilkår for bruk
        </a>
        </div>
      </div>
      <div className="org">
        <p>Org. Nummer: 933270157</p>
      </div>
    </footer>
  );
}

export default Footer;
