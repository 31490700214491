import { styled } from '@mui/material';

const ContactContainer = styled('span')(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '4px 8px',
  minWidth: '140px',
  borderRadius: 'var(--corner-radius-s)',
  gap: '4px',
  fontSize: '14px',
  fontWeight: '400',
  ...theme.customComponents.contactContainer,
}));

const ContactLabel = styled('span')(({ theme }) => ({
  ...theme.customComponents.contactLabel,
}));

export const Support = ({ label, iconColor = '#ACB8C1', linkClassName = 'email-link' }) => {
  return (
    <ContactContainer id="container">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="icon/letter">
          <path
            id="Vector"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.64334 4.30998C1.66667 5.28581 1.66667 6.85748 1.66667 9.99998C1.66667 13.1425 1.66667 14.7141 2.64334 15.69C3.61917 16.6666 5.19084 16.6666 8.33334 16.6666H11.6667C14.8092 16.6666 16.3808 16.6666 17.3567 15.69C18.3333 14.7141 18.3333 13.1425 18.3333 9.99998C18.3333 6.85748 18.3333 5.28581 17.3567 4.30998C16.3808 3.33331 14.8092 3.33331 11.6667 3.33331H8.33334C5.19084 3.33331 3.61917 3.33331 2.64334 4.30998ZM15.48 6.26665C15.586 6.39397 15.6372 6.55819 15.6222 6.72321C15.6072 6.88823 15.5273 7.04053 15.4 7.14665L13.57 8.67165C12.8308 9.28831 12.2325 9.78665 11.7033 10.1266C11.1533 10.4808 10.6175 10.7041 10 10.7041C9.38251 10.7041 8.84667 10.48 8.29584 10.1266C7.76751 9.78665 7.16917 9.28748 6.43001 8.67248L4.60001 7.14748C4.47259 7.04139 4.39254 6.88904 4.37746 6.72393C4.36238 6.55882 4.4135 6.39448 4.51959 6.26706C4.62568 6.13965 4.77803 6.0596 4.94314 6.04451C5.10825 6.02943 5.27259 6.08056 5.40001 6.18665L7.20001 7.68581C7.97751 8.33331 8.51667 8.78165 8.97334 9.07498C9.41417 9.35831 9.71334 9.45415 10.0008 9.45415C10.2883 9.45415 10.5875 9.35915 11.0283 9.07498C11.4842 8.78165 12.0242 8.33331 12.8017 7.68581L14.6008 6.18581C14.7283 6.07987 14.8926 6.02887 15.0576 6.04403C15.2226 6.05919 15.374 6.13926 15.48 6.26665Z"
            fill={iconColor}
          />
        </g>
      </svg>
      {label && <ContactLabel>{label}</ContactLabel>}
      <a className={linkClassName} href="mailto:support@hudd.no">
        support@hudd.no
      </a>
    </ContactContainer>
  );
};
