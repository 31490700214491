import { styled, Button } from '@mui/material';

export const PaginationButton = styled(Button)(({ theme }) => ({
  width: '36px',
  height: '36px',
  minWidth: 'unset',
  borderRadius: 'var(--corner-radius-s)',
  boxShadow: 'none',
  '&:disabled': { ...theme.customComponents.disabledPaginationButton },
  ...theme.customComponents.paginationButton,
}));

export const PaginationNumberSecondary = styled('span')(({ theme }) => ({
  fontSize: '20px',
  fontWeight: '600',
  ...theme.customComponents.paginationNumberSecondary,
}));

export const PaginationNumber = styled('span')(({ theme }) => ({
  fontSize: '24px',
  fontWeight: '600',
  ...theme.customComponents.paginationNumber,
}));

export const PaginationContainer = styled('div')({
  display: 'flex', 
  justifyContent: 'center', 
  gap: '12px', 
  marginTop: '16px'
});

export const PaginationNumbersContainer = styled('div')({
  display: 'flex', 
  alignItems: 'center', 
  gap: '4px'
});

export const RotatedImage = styled('img')({
  transform: 'rotate(-180deg)'
}); 