import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  typography: {
    fontFamily: '"League Spartan", sans-serif',
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#FFF',
      text: {
        primary: '#E2EAF0',
      },
    },
    secondary: {
      main: '#1B2832',
    },
    border: '1px solid #fff',
    iconStyle: {
      color: '#1B2832',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        hr: {
          backgroundColor: '#ACB8C1',
        },
        body: {
          backgroundColor: '#FFF',
          color: '#121B22',
        },
        span: {
          color: '#FFF',
        },
        'span.text': {
          color: '#516473',
        },
        'span.text-special': {
          color: '#1842A0',
        },
        'span.text-grey': {
          color: '#516473',
        },
        b: {
          color: '#121B22',
        },
        '.footer .org': {
          color: '#516473',
          fontSize: '14px',
        },
        '.footer .org p': {
          margin: '0px',
        },
        '.section-media': {
          background: '#F1F5F7',
        },
        '.date': {
          color: '#516473',
        },
        '.article-divider': {
          backgroundColor: '#D0D9E0',
        },
        '.section-posts': {
          backgroundColor: '#F8FAFB',
        },
        '.card': {
          border: '2px solid #E2EAF0',
          background: '#FFF',
        },
        '.article-container img': {
          border: '2px solid #D0D9E0',
        },
        '.note': {
          border: '2px solid #E2EAF0',
          background: 'linear-gradient(146deg, #fcfcfd 1.29%, #F8FAFB 96.72%)',
        },
        '.post-important': {
          background: 'linear-gradient(90deg, #F4B6B7 0%, #FCEDED 100%)',
        },
        '.post-regular': {
          background: 'linear-gradient(90deg, #BEC9D1 0%, #E2EAF0 100%)',
        },
        '.post-event': {
          background: 'linear-gradient(90deg, #BBCBEE 0%, #E8EEF9 114.53%)',
        },
        '.add-reaction': {
          backgroundImage: "url('/icons/add-reaction-light.svg')",
        },
        '.shield-lock': {
          backgroundImage: "url('/icons/shield-lock-light.svg')",
        },
        '.section-conduct': {
          backgroundColor: '#F8FAFB',

          a: {
            color: '#9248E4',
          },

          div: {
            '.code-point': {
              backgroundColor: '#F1F5F7',

              '.code-number': {
                color: '#9AA7B2',
                backgroundColor: '#E2EAF0',
              },
            },
          },
        },

        'a.email-link': {
          color: '#9248E4',
          fontSize: '16px',
          fontWeight: '500',
        },
        'a.email-link-header': {
          color: '#9248E4',
        },
        '.section-terms': {
          backgroundColor: '#F8FAFB',

          a: {
            color: '#9248E4',
          },

          'span.date': {
            borderRadius: 100,
            fontWeight: 500,
            padding: '10px 16px 8px 16px',
            backgroundColor: '#D0D9E0',
            color: '#1B2832',
          },

          th: {
            backgroundColor: '#F1F5F7',
          },
        },
      },
    },
  },
  customComponents: {
    CssTextField: {
      '& svg': {
        color: '#2D4354',
      },
      '& label': {
        color: '#121B22',
        fontFamily: 'League Spartan, sans-serif',
        fontWeight: 600,
      },
      '& label.Mui-focused': {
        color: '#121B22',
      },
      '& .MuiInputLabel-asterisk': {
        color: '#121B22',
      },
      '& input': {
        fontFamily: 'League Spartan, sans-serif',
        color: '#758692',
        fontWeight: 400,
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#BEC9D1',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#121B22',
      },
    },
    themeSwitch: {
      display: 'flex',
      padding: '16px 24px',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '12px',
      borderRadius: '120px',
      border: '1px solid #fff',
      background: 'linear-gradient(146deg, #fff 1.29%, #f0f0f0 96.72%)',
      '@media (max-width: 700px)': {
        width: '100%',
        padding: '12px 24px',
      },
    },
    customSwitch: {
      '& .MuiSwitch-switchBase + .MuiSwitch-track': {
        background: 'linear-gradient(146deg, #ACB8C1 1.29%, #F1F5F7 96.72%)',
        opacity: 1,
      },
    },
    phoneButton: {
      backgroundColor: '#FFF',
      '&:hover': {
        fontWeight: 600,
        backgroundColor: '#FFF',
      },
    },
    actionButton: {
      backgroundColor: '#efeff0',
      '&:hover': {
        border: '1px solid #FFF',
        backgroundColor: 'rgba(239,239,240,0.42)',
      },
    },
    reactionPopover: {
      backgroundColor: '#FFF',
      borderColor: '#E2EAF0',
    },
    disabledPaginationButton: {
      backgroundColor: '#ABB7C129',
    },
    paginationNumberSecondary: {
      color: '#9AA7B2',
    },
    paginationNumber: {
      color: '#121B22',
    },
    paginationButton: {
      backgroundColor: '#ABB7C133',
    },
    buttonContainer: {
      backgroundColor: '#FFFFFF',
    },
    contactLabel: {
      color: '#121B22',
    },
    contactContainer: {
      backgroundColor: '#F1F5F7',
    },
  },
});
